* {
  box-sizing: border-box;
  list-style-type: none;
}

body {
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: linear-gradient(to right, rgb(224, 154, 154), yellow);
}

code {
  font-family:
    source-code-pro,
    Menlo,
    Monaco,
    Consolas,
    'Courier New',
    monospace;
}

.welcome {
  width: 90%;
  margin: auto;
}

.calculator {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}

.calculator h1 {
  width: 50%;
  font-size: 20px;
}

.main {
  width: 80%;
  margin: auto;
}

.show-calculations {
  margin: auto;
  border: 1px solid white;
  background-color: grey;
  height: 10vh;
  text-align: end;
  font-size: 2rem;
  padding-top: 2%;
  padding-right: 3%;
}

.input-rows {
  height: auto;
  background-color: whitesmoke;
}

.inputRow-1,
.inputRow-2,
.inputRow-3,
.inputRow-4,
.inputRow-5 {
  display: flex;
  margin: auto;
  height: 13vh;
  justify-content: space-between;
  list-style-position: inside;
  padding-left: 0;
}

li {
  width: 100%;
  text-align: center;
  margin: auto;
}

.inputRow-1 li,
.inputRow-2 li,
.inputRow-3 li,
.inputRow-4 li,
.inputRow-5 li {
  border: 1px solid grey;
}

.orangeBG {
  background-color: orange !important;
}

.R5-li2,
.R5-li3 {
  width: 50%;
}

button {
  border: none;
  font-size: 1.5rem;
  background-color: whitesmoke;
  width: 100%;
  height: 15vh;
  cursor: pointer;
  margin: auto;
}

button:hover {
  background-color: rgb(255, 153, 0);
}

.orangeBG button {
  background-color: orange;
}

.quotes {
  font-size: 15px;
  width: 80%;
  margin: auto;
  margin-top: 10%;
  border: 0.5px solid purple;
  padding: 5%;
}

.home-btn {
  margin-left: -5%;
}

.navBar {
  width: 100%;
  margin: auto;
  margin-top: -1%;
  display: flex;
  background-color: orange;
}

.navBar button {
  font-size: 20px;
  justify-self: center;
  height: 50px;
  background-color: orange;
}

.navBar button:hover {
  background-color: orange;
}
