* {
  list-style-type: none;
  text-decoration: none;
}

nav {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: auto;
}

nav h1 {
  width: 100%;
  font-size: 30px;
}

.nav-links {
  display: flex;
  width: 100%;
  font-size: 15px;
  margin-left: 3%;
}
